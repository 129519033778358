import React from "react"
import styles from "./quote.module.scss"

const Quote = (props) => {
  return (

    <div className="box">
      <blockquote className={styles.blockquote}>
        {props.children}
      </blockquote>
      <cite className={styles.cite}>- {props.cite}</cite>
    </div>

  )
}

export default Quote
