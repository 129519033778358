import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import Quote from "../components/quote"

import { Link } from "gatsby"

const IndexPage = () => {
  return (
    <Layout title="Welcome to the Irish Skeptics Society!">
      <Head title="Home" description="Welcome to the Irish Skeptics Society" />
      <div className="columns">
        <div className="column is-two-thirds">
          <h2 className="title">Pandemic Update</h2>
          <div class="content">
            <p>
              Please note that we are currently constrained by the pandemic,
              along with the rest of the country and hope to resume our
              activities in the not too distant future.
            </p>
          </div>
          <h2 className="title">Introduction</h2>

          <div class="content">
            <p>
              The Irish Skeptics Society was founded in December 2002 by a group
              passionate about science and disgruntled at the proliferation of
              pseudoscience and nonsense. To promote science and critical
              thinking we host regular lectures at which prominent speakers from
              a wide range of backgrounds present on a chosen topic after which
              there is a question and answer session and lively debate.
            </p>
            <p>
              Past speakers include: James Randi, Matt Ridley, Emma Teeling,
              Massimo Pigliucci, John Crown, Nora Khalid, David McConnell, David
              McWilliams, Síle Lane, Kevin Mitchell, Ben Goldacre, Evelyn
              Cusack, Aoife McLysaght, Brian Hughes and Fintan O’Toole. A
              complete list of speakers and topics addressed can be seen on our{" "}
              <Link to="/events/" aria-label="Events page">
                events page
              </Link>
              .
            </p>
            <p>
              To continue and expand our work we need commited members. Please
              join today and help us to promote science and critical thinking
              and to mount a challenge to the many who actively oppose these
              ideals.
            </p>
            <p>
              If you wish to receive notice of upcoming events please sign up to{" "}
              <a href="https://groups.google.com/forum/#!forum/irish-skeptics/join">
                our mailing list
              </a>
              .
            </p>
          </div>
        </div>
        <div className="column is-one-third">
          <Quote cite="Richard Dawkins">
            <p>
              The enlightenment is under threat. So is reason. So is truth. So
              is science. I am one of those scientists who feel that it is no
              longer enough just to get on and do science. We have to devote a
              significant proportion of our time and resources to defending it
              from deliberate attack from organized ignorance.
            </p>
          </Quote>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
